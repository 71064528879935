import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

/* add icons to the library */

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);

library.add(faDiscord);

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,

  render: (h) => h(App),
}).$mount("#app");
